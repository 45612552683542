<template>
  <v-app>
    <v-main>
      <v-app-bar flat dense>
        <v-toolbar-title>
          <router-link to="/">
            <v-img src="@/assets/pulse-logo.png" alt="PULSE" contain width="160px" height="auto" />
          </router-link>
        </v-toolbar-title>
        <Auth />
      </v-app-bar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer flat dense>
      <v-col class="text-caption font-weight-light grey--text pa-0">
        <v-icon left x-small color="grey">mdi-wrench</v-icon>
        Built by
        <strong>DevFactory TPM</strong>
      </v-col>
      <v-col class="text-right pa-0">
        <v-btn
          href="https://mail.google.com/chat/u/0/#chat/space/AAAAnhRxLMQ"
          target="_blank"
          color="grey"
          small dense outlined
        >
          <v-icon left>mdi-medical-bag</v-icon>
          Support
        </v-btn>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
  import { initializeAuth } from "@/auth";
  import Auth from '@/components/Auth';

  export default {
    name: 'App',
    components: {
      Auth
    },
    async beforeMount() {
      await initializeAuth();
    }
  };
</script>
