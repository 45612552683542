<template>
  <v-dialog v-model="modalVisible" width="400">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="grey" class="mx-2" outlined>
        <v-icon left>mdi-close</v-icon>
        Reject
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Enter Reject Reason
      </v-card-title>
      <v-card-text>
        <v-select v-model="reason" :items="possibleReasons" dense outlined />
        <v-text-field v-model="details" dense outlined />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="() => action(reason, details)" outlined :disabled="!reason || !details || details.length < 20">
          <v-icon left>mdi-close</v-icon>
          Reject
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RejectButton',
  props: {
    action: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      modalVisible: false,
      reason: undefined,
      details: ''
    };
  },
  computed: {
    possibleReasons() {
      return [
        'DO_NOT_HAVE_ACCESS',
        'DO_NOT_KNOW',
        'DO_NOT_DO_THIS_TYPE_OF_WORK'
      ];
    }
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    }
  }
};
</script>
