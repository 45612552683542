<template>
  <v-btn @click="() => action(result)" :color="color" outlined>
    <v-icon left>{{ icon }}</v-icon>
    {{ result }}
  </v-btn>
</template>

<script>
export default {
  name: 'QcResultButton',
  props: {
    action: {
      type: Function,
      required: true
    },
    result: {
      type: String,
      required: true,
      validator(value) {
        return ['PASSED', 'FAILED'].includes(value);
      }
    }
  },
  computed: {
    color() {
      return this.result === 'PASSED' ? 'success' : 'error';
    },
    icon() {
      return this.result === 'PASSED' ? 'mdi-check' : 'mdi-close';
    }
  }
};
</script>
