<template>
  <v-simple-table dense>
    <tbody>
    <tr>
      <th class="grey--text" colspan="2">
        <v-icon small left class="grey--text">mdi-wrench</v-icon>
        Work
      </th>
    </tr>
    <tr>
      <th class="grey--text">Type</th>
      <td>{{ task.taskType }}</td>
    </tr>
    <tr>
      <th class="grey--text">Assembly Line</th>
      <td v-if="task.assemblyLine">{{ task.assemblyLine.name }}</td>
      <td v-else class="grey--text">Standalone work unit</td>
    </tr>
    <tr>
      <th class="grey--text">Work Unit</th>
      <td v-if="task.workUnit">{{ task.workUnit.name }}</td>
      <td v-else class="grey--text">None</td>
    </tr>
    <tr>
      <th class="grey--text">Name</th>
      <td>{{ task.name }}</td>
    </tr>
    <tr>
      <th class="grey--text">Description</th>
      <td v-if="task.description">{{ task.description }}</td>
      <td v-else class="grey--text">None</td>
    </tr>
    <tr>
      <th class="grey--text">Priority</th>
      <td>{{ task.priority }}</td>
    </tr>
    <tr>
      <th class="grey--text">Input Artifacts</th>
      <td v-if="task.inputArtifactURLs && task.inputArtifactURLs.length > 0">
        <div v-for="(url, i) in task.inputArtifactURLs" :key="url">
          <a :href="url" target="_blank">Input Artifact Link #{{ i + 1 }}</a>
        </div>
      </td>
      <td v-else class="grey--text">None</td>
    </tr>
    <tr>
      <th class="grey--text">Artifacts</th>
      <td v-if="task.artifactURLs && task.artifactURLs.length > 0">
        <div v-for="(url, i) in task.artifactURLs" :key="url">
          <a :href="url" target="_blank">Artifact Link #{{ i + 1 }}</a>
        </div>
      </td>
      <td v-else class="grey--text">None</td>
    </tr>
    <tr>
      <th class="grey--text">Quality Bar</th>
      <td>
        <a :href="task.inputQualityBar" target="_blank">QB Link</a>
      </td>
    </tr>
    <tr>
      <th class="grey--text" colspan="2">
        <v-icon small left class="grey--text">mdi-alarm</v-icon>
        SLA
      </th>
    </tr>
    <tr>
      <th class="grey--text">Total</th>
      <td>{{ formatTime(task.sla) }}</td>
    </tr>
    <tr>
      <th class="grey--text">Elapsed</th>
      <td>{{ formatTime(elapsedSla) }}</td>
    </tr>
    <tr>
      <th class="grey--text">Remaining</th>
      <td>{{ formatTime(task.sla - elapsedSla) }}</td>
    </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { formatTime } from '@/services/time';

export default {
  name: 'TaskView',
  props: {
    task: {
      type: Object,
      required: true
    },
    refresh: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      slaInterval: undefined,
      now: Date.now()
    };
  },
  computed: {
    elapsedSla() {
      const fetchedAt = this.task ? new Date(this.task.fetchedAt).getTime() : undefined;
      return fetchedAt ? Math.floor((this.now - fetchedAt) / 1000) : undefined;
    }
  },
  methods: {
    formatTime
  },
  async mounted() {
    this.slaInterval = setInterval(async () => {
      this.now = Date.now();
      if (this.task && this.elapsedSla > this.task.sla) {
        await this.refresh();
      }
    }, 1000);
  },
  beforeDestroy() {
    if (this.slaInterval) {
      clearInterval(this.slaInterval);
    }
  },
};
</script>
