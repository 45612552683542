import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { buildApolloProvider } from '@/plugins/apollo';

const apolloProvider = buildApolloProvider();
const render = (h) => h(App);

Vue.config.productionTip = false;

new Vue({ router, vuetify, apolloProvider, render })
  .$mount('#app');
