<template v-slot="{ result }">
  <v-sheet class="transparent">
    <v-card outlined>
      <v-card-title class="font-weight-thin">
        <v-icon left class="blue-grey--text">{{ titleIcon }}</v-icon>
        {{ this.type }} Work
      </v-card-title>
      <v-card-text v-if="loading">
        <v-progress-circular indeterminate />
        Loading...
      </v-card-text>
      <v-card-text v-else-if="currentTask">
        <TaskView :task="currentTask" :refresh="getCurrentTask" />
      </v-card-text>
      <v-card-text v-else class="grey--text">
        No {{ type }} work assigned
      </v-card-text>
      <v-card-actions v-if="!loading">
        <SubmitWorkButton v-if="showSubmitWorkButton" :action="submitWork" :artifacts="currentTask.inputArtifactURLs" />
        <QcResultButton v-if="showQcResultButtons" :action="submitQc" result="PASSED" />
        <QcResultButton v-if="showQcResultButtons" :action="submitQc" result="FAILED" />
        <RejectButton v-if="currentTask" :action="rejectTask" />
        <FetchButton v-if="!currentTask" :action="fetchTask" />
        <v-spacer />
        <RefreshButton :action="getCurrentTask" />
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="showSnackbar" color="warning" outlined dense top right elevation="20">
      <v-icon left color="warning">mdi-alert-circle-outline</v-icon>
      {{ errorMessage }}
    </v-snackbar>
  </v-sheet>
</template>

<script>
import {
  completeDoTask,
  completeQcTask,
  fetchDoTask,
  fetchQcTask,
  getCurrentTasks,
  rejectDoTask,
  rejectQcTask
} from '@/services/pulse';
import TaskView from '@/components/tasks/TaskView';
import RejectButton from '@/components/tasks/RejectButton';
import FetchButton from '@/components/tasks/FetchButton';
import SubmitWorkButton from '@/components/tasks/SubmitWorkButton';
import RefreshButton from '@/components/tasks/RefreshButton';
import QcResultButton from '@/components/tasks/QcResultButton';

export default {
  name: 'CurrentTask',
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['DO', 'QC'].includes(value);
      }
    },
    refreshOther: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      currentTask: undefined,
      loading: false,
      errorMessage: undefined
    };
  },
  computed: {
    titleIcon() {
      return this.type === 'DO' ? 'mdi-account-hard-hat-outline' : 'mdi-scale-balance';
    },
    showSubmitWorkButton() {
      return !!this.currentTask && this.type === 'DO';
    },
    showQcResultButtons() {
      return !!this.currentTask && this.type === 'QC';
    },
    showSnackbar: {
      get() {
        return !!this.errorMessage;
      },
      set(value) {
        if (!value) {
          this.errorMessage = undefined;
        }
      }
    }
  },
  methods: {
    async getCurrentTask() {
      try {
        this.loading = true;
        if (this.refreshOther) {
          this.refreshOther(); // Don't await this, just fire and forget.
        }
        this.errorMessage = '';
        const currentTasks = await getCurrentTasks(this.$apollo);
        this.currentTask = currentTasks.find(({ taskType }) => taskType.endsWith(this.type));
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
        // If we didn't get a DO task, we might have got a QC task, so refresh the other panel.
      }
    },
    async processTask(processor) {
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        this.errorMessage = '';
        await processor(this.$apollo, this.currentTask);
        await this.getCurrentTask();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async fetchTask() {
      await this.processTask(() => this.type === 'DO' ? fetchDoTask(this.$apollo) : fetchQcTask(this.$apollo));
    },
    async submitWork(artifactURLs) {
      await this.processTask(() => completeDoTask(this.$apollo, this.currentTask.id, artifactURLs));
    },
    async submitQc(qcOutcome) {
      await this.processTask(() => completeQcTask(this.$apollo, this.currentTask.id, qcOutcome));
    },
    async rejectTask(reason, details) {
      await this.processTask(() => this.type === 'DO'
        ? rejectDoTask(this.$apollo, reason, details)
        : rejectQcTask(this.$apollo, reason, details)
      );
    }
  },
  async mounted() {
    await this.getCurrentTask();
  },
  components: {
    TaskView,
    FetchButton,
    SubmitWorkButton,
    QcResultButton,
    RejectButton,
    RefreshButton
  }
};
</script>
