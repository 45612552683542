import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import {
  notifyError,
  notifyInitialized,
  notifyLoading,
  notifyNewPasswordRequired,
  notifySuccess
} from '@/auth/callbacks';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.VUE_APP_USER_POOL_ID,
  ClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID
});

const currentUser = {
  cognitoUser: null,
  attributes: {},
};

export const getCurrentUser = () => currentUser;

export const userIsLoggedIn = () => !!currentUser.attributes?.email;

export const initializeAuth = async () => {
  currentUser.attributes = {};
  currentUser.cognitoUser = userPool.getCurrentUser();

  try {
    await new Promise((resolve, reject) => {
      if (currentUser.cognitoUser) {
        currentUser.cognitoUser.getSession((error) =>
          (error ? reject(error) : resolve()));
      } else {
        resolve();
      }
    });

    const attributes = await new Promise((resolve, reject) => {
      if (currentUser.cognitoUser && currentUser.cognitoUser.getUsername()) {
        currentUser.cognitoUser.getUserAttributes((error, response) =>
          (error ? reject(error) : resolve(response || [])));
      } else {
        resolve([]);
      }
    });

    if (attributes) {
      currentUser.attributes = attributes.reduce(
        (result, attribute) => ({
          ...result,
          [attribute.Name]: attribute.Value
        }),
        {}
      );
    }

    await notifyInitialized();
  } catch (e) {
    await notifyError(e);
  }
};

export const login = async (username, password) => {
  await notifyLoading();

  currentUser.cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool
  });

  await new Promise((resolve, reject) => {
    if (currentUser.cognitoUser) {
      currentUser.cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: username,
          Password: password
        }),
        {
          async onFailure(error) {
            await notifyError(error);
            reject(error);
          },
          async onSuccess() {
            await initializeAuth();
            await notifySuccess(`You have successfully logged in as ${currentUser.attributes.name}`);
            resolve();
          },
          async newPasswordRequired(userAttributes) {
            await notifyNewPasswordRequired(userAttributes);
          }
        }
      );
    }
  });
};

export const logout = async () => {
  await notifyLoading();

  await new Promise((resolve) => {
    if (currentUser.cognitoUser) {
      currentUser.cognitoUser.signOut(async () => {
        await notifySuccess('You have logged out.');
        await initializeAuth();
        resolve();
      });
    } else {
      notifySuccess('');
    }
  });
};

export const completeNewPasswordChallenge = async (newPassword) => {
  await notifyLoading();

  await new Promise((resolve, reject) => {
    if (currentUser.cognitoUser) {
      currentUser.cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          async onFailure(error) {
            await notifyError(error);
            reject(error);
          },
          async onSuccess() {
            await initializeAuth();
            await notifySuccess(`You have successfully set a new password`);
            resolve();
          }
        });
    }
  })
};
