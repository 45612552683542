<template>
  <v-sheet v-if="userIsLoggedIn" class="transparent">
    <v-row>
      <v-col cols="12" md="6">
        <CurrentTask type="DO" ref="doTasks" :refresh-other="refreshQc" />
      </v-col>
      <v-col cols="12" md="6">
        <CurrentTask type="QC" ref="qcTasks" :refresh-other="refreshDo" />
      </v-col>
    </v-row>
  </v-sheet>
  <v-sheet v-else class="text-center transparent grey--text">
    Please login first
  </v-sheet>
</template>

<script>
import CurrentTask from '@/components/CurrentTask';
import { userIsLoggedIn } from '@/auth';

export default {
  name: 'Home',
  computed: {
    userIsLoggedIn
  },
  components: {
    CurrentTask
  },
  methods: {
    async refreshDo() {
      if (!this.$refs.doTasks.loading) {
        await this.$refs.doTasks.getCurrentTask();
      }
    },
    async refreshQc() {
      if (!this.$refs.qcTasks.loading) {
        await this.$refs.qcTasks.getCurrentTask();
      }
    }
  }
};
</script>
