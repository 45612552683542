<template>
  <v-btn @click="() => action(artifacts)" color="success" outlined>
    <v-icon left>mdi-check</v-icon>
    Submit
  </v-btn>
</template>

<script>
export default {
  name: 'SubmitWorkButton',
  props: {
    action: {
      type: Function,
      required: true
    },
    artifacts: {
      type: Array,
      required: false
    }
  }
};
</script>
