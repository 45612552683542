import gql from 'graphql-tag';

export const getCurrentTasks = async ($apollo) => {
  const response = await $apollo.query({
    query: gql`
      query ListCurrentTasks {
        currentTasks {
          task {
            id
            assemblyLine {
              id
              name
              active
            }
            workUnit {
              id
              name
            }
            taskType
            priority
            sla
            name
            description
            inputQualityBar
            inputArtifactURLs
            artifactURLs
            fetchedAt
          }
        }
      }`,
    fetchPolicy: 'no-cache'
  });
  const tasks = (response?.data?.currentTasks || []);
  return tasks.map(({ task }) => task);
};

export const fetchDoTask = async ($apollo) => {
  const response = await $apollo.mutate({
    mutation: gql`
      mutation FetchTask {
        fetchTask {
          id
          fetchedAt
        }
      }
    `
  });
  return response?.data?.fetchTask;
};

export const fetchQcTask = async ($apollo) => {
  const response = await $apollo.mutate({
    mutation: gql`
      mutation FetchQcTask {
        fetchQcTask {
          id
          fetchedAt
        }
      }
    `
  });
  return response?.data?.fetchQcTask;
};

export const completeDoTask = async ($apollo, taskId, artifactURLs) => {
  const response = await $apollo.mutate({
    mutation: gql`
      mutation CompleteDoTask($input: CompleteDoTaskInput!) {
        completeDoTask(input: $input) {
          id
          status
          artifactURLs
        }
      }
    `,
    variables: {
      input: {
        taskId,
        artifactURLs
      }
    }
  });
  if (!response?.data?.completeDoTask) {
    console.error('No response to complete DO task', response);
  } else if (response?.data?.completeDoTask?.status !== 'DONE') {
    console.error('DO task not completed', response);
  }
};

export const completeQcTask = async ($apollo, taskId, qcOutcome) => {
  const response = await $apollo.mutate({
    mutation: gql`
      mutation CompleteQcTask($input: CompleteQcTaskInput!) {
        completeQcTask(input: $input) {
          id
          status
        }
      }
    `,
    variables: {
      input: {
        taskId,
        qcOutcome
      }
    }
  });
  if (!response?.data?.completeQcTask) {
    console.error('No response to complete QC task', response);
  } else if (response?.data?.completeQcTask?.status !== 'DONE') {
    console.error('QC task not completed', response);
  }
};

export const rejectDoTask = async ($apollo, reason, details) => {
  const response = await $apollo.mutate({
    mutation: gql`
      mutation RejectDoTask($input: RejectTaskInput!) {
        rejectDoTask(input: $input) {
          id
          status
        }
      }
    `,
    variables: {
      input: {
        reason,
        details
      }
    }
  });
  if (!response?.data?.rejectDoTask) {
    console.error('No response to reject DO task', response);
  } else if (response?.data?.rejectDoTask?.status !== 'REJECTED') {
    console.error('DO task not rejected', response);
  }
};

export const rejectQcTask = async ($apollo, reason, details) => {
  const response = await $apollo.mutate({
    mutation: gql`
      mutation RejectQcTask($input: RejectTaskInput!) {
        rejectQcTask(input: $input) {
          id
          status
        }
      }
    `,
    variables: {
      input: {
        reason,
        details
      }
    }
  });
  if (!response?.data?.rejectQcTask) {
    console.error('No response to reject QC task', response);
  } else if (response?.data?.rejectQcTask?.status !== 'REJECTED') {
    console.error('QC task not rejected', response);
  }
};
