<template>
  <v-btn @click="() => action()" color="grey" outlined>
    <v-icon left>mdi-refresh</v-icon>
    Refresh
  </v-btn>
</template>

<script>
export default {
  name: 'RefreshButton',
  props: {
    action: {
      type: Function,
      required: true
    }
  }
}
</script>
