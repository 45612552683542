import Vue from 'vue';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-boost';
import { getCurrentUser } from '@/auth';

Vue.use(VueApollo);

export const buildApolloProvider = () =>
  new VueApollo({
    defaultClient: new ApolloClient({
      name: 'PulseApi',
      uri: process.env.VUE_APP_API_BASE_URL,
      fetch: (uri, options) =>
        fetch(uri, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: getCurrentUser()?.cognitoUser?.signInUserSession?.accessToken?.jwtToken
          }
        })
    })
  });
