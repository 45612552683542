export const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  const components = [
    hours > 0 ? `${hours < 10 ? '0' : ''}${hours}h` : '',
    minutes > 0 ? `${hours > 0 && minutes < 10 ? '0' : ''}${minutes}m` : '',
    seconds > 0 ? `${minutes > 0 && seconds < 10 ? '0' : ''}${seconds}s` : ''
  ];
  return components.join(' ').trim();
};
