<template>
  <v-btn @click="() => action()" color="success" outlined>
    <v-icon left>mdi-dog-side</v-icon>
    Fetch
  </v-btn>
</template>

<script>
export default {
  name: 'FetchButton',
  props: {
    action: {
      type: Function,
      required: true
    }
  }
}
</script>
