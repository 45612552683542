<template>
  <v-row>
    <v-col v-if="loading" class="text-right grey--text">
      <v-progress-circular indeterminate />
      Loading...
    </v-col>
    <v-col v-else-if="!userIsLoggedIn" class="text-right">
      <v-dialog v-model="showDialog" width="400" persistent>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined dense>
            <v-icon left>mdi-login</v-icon>
            Login
          </v-btn>
        </template>
        <v-form @submit="login" class="ma-0 pa-0 d-inline-block" v-if="!newPasswordRequired">
          <v-card>
            <v-card-title>
              Enter credentials
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    type="text"
                    placeholder="Username"
                    v-model="username"
                    flat outlined dense hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    type="password"
                    placeholder="Password"
                    v-model="password"
                    flat outlined dense hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="success" outlined dense>
                <v-icon left>mdi-login</v-icon>
                Login
              </v-btn>
              <v-btn color="grey" @click="showDialog = false" outlined dense>
                <v-icon left>mdi-close</v-icon>
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-form @submit="completeNewPasswordChallenge" class="ma-0 pa-0 d-inline-block" v-else>
          <v-card>
            <v-card-title>
              Change your password
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    type="text"
                    placeholder="Username"
                    v-model="username"
                    disabled flat outlined dense hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    type="password"
                    placeholder="New Password"
                    v-model="password"
                    flat outlined dense hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    type="password"
                    placeholder="Repeat New Password"
                    v-model="passwordRepeat"
                    flat outlined dense hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="success" :disabled="!passwordsMatch" outlined dense>
                <v-icon left>mdi-check</v-icon>
                Submit
              </v-btn>
              <v-btn color="grey" @click="showDialog = false" outlined dense>
                <v-icon left>mdi-close</v-icon>
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-col>
    <v-col v-else class="text-right">
    <span class="grey--text font-weight-light">
      {{ currentUser.cognitoUser.username }}
    </span>
      <v-btn @click="logout" color="grey" outlined class="ml-3">
        <v-icon left>mdi-logout</v-icon>
        Logout
      </v-btn>
    </v-col>
    <v-snackbar v-model="showSnackbar" color="warning" outlined dense top right>
      <v-icon left>mdi-exclamation-circle-outline</v-icon>
      {{ errorMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { getCurrentUser, userIsLoggedIn, login, logout, completeNewPasswordChallenge } from '@/auth';
import { registerAuthCallback } from '@/auth/callbacks';

export default {
  name: 'Auth',
  data() {
    return {
      currentUser: getCurrentUser(),
      loading: false,
      showDialog: false,
      username: '',
      password: '',
      newPasswordRequired: false,
      passwordRepeat: '',
      errorMessage: undefined
    };
  },
  computed: {
    userIsLoggedIn() {
      return userIsLoggedIn();
    },
    passwordsMatch() {
      return this.password === this.passwordRepeat;
    },
    showSnackbar: {
      get() {
        return !!this.errorMessage;
      },
      set(value) {
        if (!value) {
          this.errorMessage = undefined;
        }
      }
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        await login(this.username, this.password);
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async logout() {
      try {
        this.loading = true;
        await logout();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async completeNewPasswordChallenge() {
      try {
        this.loading = true;
        await completeNewPasswordChallenge(this.password);
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    }
  },
  beforeMount() {
    registerAuthCallback((action, param) => {
      switch (action) {
        case 'loading':
          this.loading = true;
          break;
        case 'initialized':
          this.loading = false;
          break;
        case 'newPasswordRequired':
          this.newPasswordRequired = true;
          this.showDialog = true;
          this.loading = false;
          break;
        case 'success':
          this.loading = false;
          break;
        case 'error':
          this.loading = false;
          this.errorMessage = param;
          break;
      }
    });
  }
};
</script>
