const authCallbacks = [];

export const registerAuthCallback = (callback) => {
  authCallbacks.push(callback);
};

export const notifyInitialized = async () => {
  await Promise.all(authCallbacks.map(async (callback) => {
    await callback('initialized', '');
  }));
};

export const notifyLoading = async () => {
  await Promise.all(authCallbacks.map(async (callback) => {
    await callback('loading', '');
  }));
};

export const notifySuccess = async (message) => {
  await Promise.all(authCallbacks.map(async (callback) => {
    await callback('success', message);
  }));
};

export const notifyError = async (error) => {
  const message = (process.env.NODE_ENV === 'production') ? 'An error occurred' : `${error}`;
  await Promise.all(authCallbacks.map(async (callback) => {
    await callback('error', message);
  }));
};

export const notifyNewPasswordRequired = async () => {
  await Promise.all(authCallbacks.map(async (callback) => {
    await callback('newPasswordRequired');
  }));
};
